import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit } from "@angular/core";
import { DatepickerModel } from "../../../pojo/DatepickerModel";
import { EventI } from "../../../pojo/EventI";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { S25Datefilter } from "../../s25-dateformat/s25.datefilter.service";
import { ReservationI } from "../../../pojo/ReservationI";
import { S25ItemI } from "../../../pojo/S25ItemI";
import { OccurrenceI } from "../../../pojo/OccurrenceI";

interface Reservation {
    date: string;
    start: Date;
    end: Date;
    resources: { resource_id: number }[];
    spaces: { space_id: number }[];
    formData: ReservationI;
}

interface DateMap {
    [key: string]: number;
}

export interface OccurrenceModel extends ReservationI {
    locations: ReservationI["space"];
    resources: ReservationI["res"];
    rsrvId?: number;
    profile_code?: string;
    mapping?: OccurrenceI.Mapping;
    eventId?: number;
}

@TypeManagerDecorator("s25-ng-occurrences-config")
@Component({
    selector: "s25-ng-occurrences-config",
    template: `<div
        *ngIf="init"
        class="occurrence-config--wrapper c-margin-bottom--single c-margin-top--single"
        aria-label="Configure Occurrences"
        tabindex="0"
    >
        <div class="flex-form--wrapper">
            <div class="start-date-container">
                <label class="c-margin-bottom--quarter c-margin-top--half" for="startDateContainer">Start Date</label>
                <s25-datepicker
                    [(modelValue)]="startDateModel"
                    [inputId]="'startDateContainer'"
                    (modelValueChange)="updateStartDate()"
                ></s25-datepicker>

                <label class="occurrence-header c-margin-top--half" for="occurrenceDetails"> Occurrence Mapping </label>
                <select
                    class="cn-form__control"
                    [(ngModel)]="mapping"
                    (ngModelChange)="updateMapping()"
                    id="occurrenceDetails"
                    name="occurrenceDetails"
                >
                    <option value="one-by-one">One-to-one</option>
                    <option value="date-by-date">Date-to-date</option>
                    <option value="none">No Mapping</option>
                </select>
            </div>

            <div class="form-separator"></div>

            <div class="occurrence-view" tabindex="0">
                <div
                    *ngFor="let res of reservations; let i = index"
                    class="original-occurrence"
                    [ngClass]="{ 'date-mapped': mapping === 'date-by-date' }"
                >
                    <div class="occurrence-date" [ngClass]="{ mapped: showMapping }">
                        <div>{{ res.date }}</div>
                        <svg
                            *ngIf="showMapping && mapping !== 'date-by-date'"
                            class="c-svgIcon c-margin-right--half"
                            role="img"
                        >
                            <title>Expand</title>
                            <use
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#arrow--right-down"
                            ></use>
                        </svg>
                        <span
                            *ngIf="showMapping && mapping !== 'date-by-date'"
                            [ngClass]="{ 'multi-line': isMultipleDays }"
                            >{{ occurrences[i].date }}</span
                        >
                    </div>
                    <div class="occurrence-times">
                        <div>{{ res.start }}</div>
                        <div>{{ res.end }}</div>
                    </div>
                    <div class="occurrence-objects">
                        <ng-container *ngIf="mapping === 'one-by-one' || mapping === 'date-by-date'">
                            <div *ngIf="!res.spaces && !res.resources" class="no-details">
                                No Additional Details Found
                            </div>
                            <div *ngFor="let space of res.spaces">
                                <s25-item-space
                                    [modelBean]="{ itemId: space.space_id }"
                                    [includeTypeIcon]="true"
                                ></s25-item-space>
                            </div>
                            <div *ngFor="let resource of res.resources">
                                <s25-item-resource
                                    [modelBean]="{ itemId: resource.resource_id }"
                                    [includeTypeIcon]="true"
                                ></s25-item-resource>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="mapping === 'none'">
                            <div *ngIf="!reservations[0].spaces && !reservations[0].resources" class="no-details">
                                No Additional Details Found
                            </div>
                            <div *ngFor="let space of allLocations">
                                <s25-item-space
                                    [modelBean]="{ itemId: space.itemId }"
                                    [includeTypeIcon]="true"
                                ></s25-item-space>
                            </div>
                            <div *ngFor="let resource of allResources">
                                <s25-item-resource
                                    [modelBean]="{ itemId: resource.itemId }"
                                    [includeTypeIcon]="true"
                                ></s25-item-resource>
                            </div>
                        </ng-container>
                    </div>

                    <div *ngIf="showMapping && mapping === 'date-by-date'" class="mapped-occurrence">
                        <svg class="c-svgIcon" role="img">
                            <title>Expand</title>
                            <use
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#arrow--right-down"
                            ></use>
                        </svg>
                        <div class="occurrence-date">{{ occurrences[i].date }}</div>
                        <div class="occurrence-times">
                            <div>{{ occurrences[i].start }}</div>
                            <div>{{ occurrences[i].end }}</div>
                        </div>
                        <div
                            class="occurrence-objects"
                            [ngClass]="{ 'no-details': !occurrences[i].spaces && !occurrences[i].resources }"
                        >
                            <div *ngIf="!occurrences[i].spaces && !occurrences[i].resources" class="no-details">
                                No Additional Details Found
                            </div>
                            <div *ngFor="let space of occurrences[i].spaces">
                                <s25-item-space
                                    [modelBean]="{ itemId: space.space_id }"
                                    [includeTypeIcon]="true"
                                ></s25-item-space>
                            </div>
                            <div *ngFor="let resource of occurrences[i].resources">
                                <s25-item-resource
                                    [modelBean]="{ itemId: resource.resource_id }"
                                    [includeTypeIcon]="true"
                                ></s25-item-resource>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25OccurrencesConfigComponent implements OnInit {
    @Input() eventData: EventI;
    @Input() onChange: (data: OccurrenceModel[]) => void;

    init: boolean = false;
    mapping: OccurrenceI.Mapping = "one-by-one";
    startDateModel: DatepickerModel;
    occurrences: Reservation[] = []; // temporary occurrence reservations based on current mapping/date selections for displaying on visual aid
    reservations: Reservation[]; // occurrence reservations from original event
    showMapping: boolean;
    dateMap: DateMap = {};
    isMultipleDays: boolean;
    diffDays: number = 0;
    occurrenceModel: OccurrenceModel[] = []; // final occurrence data sent to the event form on submit
    allResources: S25ItemI[] = [];
    allLocations: S25ItemI[] = [];

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    ngOnInit() {
        this.startDateModel = { date: new Date(this.eventData?.summary?.prof[0]?.init_start_dt), showToday: true };

        this.reservations = this.getReservations();

        this.onOccChange();

        this.init = true;

        this.cd.detectChanges();
    }

    updateStartDate() {
        this.diffDays = S25Util.date.diffDays(this.eventData?.summary?.prof[0]?.init_start_dt, this.startDateModel);

        if (this.diffDays !== 0) {
            this.occurrenceModel = [];

            this.occurrences = this.reservations.map((res: Reservation) => {
                let dates;
                if (this.isMultipleDays) {
                    dates = res.date
                        .split(" - ")
                        .map((date: string) => {
                            return S25Datefilter.transform(
                                S25Util.date.addDays(date, this.diffDays),
                                this.eventData.dateFormat,
                            );
                        })
                        .join(" - ");
                }

                this.occurrenceModel.push({
                    startDt: S25Util.date.addDays(res.formData.startDt, this.diffDays),
                    endDt: S25Util.date.addDays(res.formData.endDt, this.diffDays),
                    locations: res.formData.space ? [...res.formData.space] : [],
                    resources: res.formData.res ? [...res.formData.res] : [],
                    rsrvId: res.formData.rsvId,
                });

                return {
                    date:
                        dates ||
                        S25Datefilter.transform(
                            S25Util.date.addDays(res.date, this.diffDays),
                            this.eventData.dateFormat,
                        ),
                    start: res.start,
                    end: res.end,
                    resources: res.resources,
                    spaces: res.spaces,
                    formData: {
                        startDt: S25Util.date.addDays(res.formData.startDt, this.diffDays),
                        endDt: S25Util.date.addDays(res.formData.endDt, this.diffDays),
                        state: res.formData.state,
                        rsvId: res.formData.rsvId,
                        locations: res.formData.space ? [...res.formData.space] : [],
                        resources: res.formData.res ? [...res.formData.res] : [],
                    },
                };
            });

            this.showMapping ||= true;
            this.updateMapping();
            return;
        }

        this.showMapping &&= false;
        this.updateMapping();
    }

    updateMapping() {
        if (
            this.mapping === "date-by-date" &&
            this.occurrences[0] &&
            S25Util.date.isBetweenEqual(
                this.occurrences[0].date,
                this.reservations[0].date,
                this.reservations[this.reservations.length - 1].date,
            )
        ) {
            this.occurrences.forEach((occ: Reservation, i) => {
                const resData = this.dateMap[occ.date]
                    ? this.reservations[this.dateMap[occ.date]]
                    : this.reservations[0];

                occ.start = resData.start;
                occ.end = resData.end;
                occ.resources = resData.resources ? [...resData.resources] : [];
                occ.spaces = resData.spaces ? [...resData.spaces] : [];

                this.occurrenceModel[i].locations = resData.formData.space ? [...resData.formData.space] : [];
                this.occurrenceModel[i].resources = resData.formData.res ? [...resData.formData.res] : [];
            });
        } else if (this.mapping === "none") {
            if (this.diffDays === 0) {
                this.occurrenceModel = [];

                this.reservations.forEach((res: Reservation) => {
                    this.occurrenceModel.push({
                        startDt: res.formData.startDt,
                        endDt: res.formData.endDt,
                        locations: [...this.allLocations],
                        resources: [...this.allResources],
                    });
                });
            } else if (this.occurrenceModel.length && this.occurrenceModel.length > 0) {
                this.occurrenceModel.forEach((occ: OccurrenceModel) => {
                    occ.locations = [...this.allLocations];
                    occ.resources = [...this.allResources];
                });
            }
        }
        this.onOccChange();

        this.cd.detectChanges();
    }

    getReservations(): Reservation[] {
        return this.eventData?.summary?.occurrences?.map((res: ReservationI, i: number) => {
            this.dateMap[S25Datefilter.transform(res.startDt, this.eventData.dateFormat)] = i;

            const isMultipleDays =
                S25Datefilter.transform(res.startDt, this.eventData.dateFormat) !==
                S25Datefilter.transform(res.endDt, this.eventData.dateFormat);
            const dateRange =
                isMultipleDays &&
                `${S25Datefilter.transform(res.startDt, this.eventData.dateFormat)} - ${S25Datefilter.transform(
                    res.endDt,
                    this.eventData.dateFormat,
                )}`;
            this.isMultipleDays = isMultipleDays;

            this.allResources = [...this.allResources];
            res.res?.forEach((resource) => {
                const found = this.allResources.find((el) => el.itemId === resource.itemId);
                if (!found) this.allResources.push(resource);
            });

            this.allLocations = [...this.allLocations];
            res.space?.forEach((space) => {
                const found = this.allLocations.find((el) => el.itemId === space.itemId);
                if (!found) this.allLocations.push(space);
            });

            this.occurrenceModel.push({
                startDt: res.startDt,
                endDt: res.endDt,
                locations: res.space,
                resources: res.res,
            });

            return {
                date: dateRange || S25Datefilter.transform(res.startDt, this.eventData.dateFormat),
                start: S25Datefilter.transform(res.startDt, this.eventData.timeFormat),
                end: S25Datefilter.transform(res.endDt, this.eventData.timeFormat),
                resources: res.res?.map((resource: S25ItemI) => {
                    return { resource_id: resource.itemId };
                }),
                spaces: res.space?.map((space: S25ItemI) => {
                    return { space_id: space.itemId };
                }),
                formData: res,
            };
        });
    }

    onOccChange() {
        // if (this.diffDays === 0 && this.mapping !== "none") {
        //     return;
        // }

        let profileCode = this.eventData?.summary?.prof[0]?.profile_code;
        if (this.diffDays !== 0) {
            if (
                this.eventData?.summary?.prof[0]?.profile_code &&
                this.eventData?.summary?.prof[0]?.profile_code[0] === "W"
            ) {
                profileCode = this.eventData?.summary?.prof[0]?.profile_code.split(" ");
                const oldScheme = profileCode.slice(1, -1);

                let newScheme = [];
                for (let i = 0; i < oldScheme.length; i++) {
                    newScheme.push(this.occurrenceModel[i].startDt.toString().slice(0, 2).toUpperCase());
                }

                profileCode = [
                    ...profileCode.slice(0, 1),
                    ...newScheme,
                    ...profileCode.slice(newScheme.length + 1),
                ].join(" ");
            }
        }

        this.occurrenceModel[0].profile_code = profileCode ?? "adhoc";
        this.occurrenceModel[0].eventId = this.eventData?.eventId;
        this.occurrenceModel[0].mapping = this.mapping;

        this.onChange(this.occurrenceModel);
    }
}
