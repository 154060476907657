import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";
import { S25EditableModule } from "../s25-editable/s25.editable.module";
import { S25ItemModule } from "../s25-item/s25.item.module";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { S25EvRequirementsComponent } from "./ev.requirements.component";
import { S25EditableContactRolesComponent } from "./ev.editable.contacts.component";
import { S25EditableAdditionalOrgsComponent } from "./ev.editable.additional.orgs.component";
import { S25NotificationPolicyModule } from "../s25-notification-policy/s25.notification.policy.module";
import { S25OrgDetailsModule } from "../organization-details/s25.org.details.module";
import { S25MultiselectModule } from "../s25-multiselect/s25-multiselect.module";
import { S25OccurrencesConfigComponent } from "./occurrences/s25.occurrences.config.component";
import { S25ToggleButtonModule } from "../s25-toggle-button/s25.toggle.button.module";
import { S25DatepickerModule } from "../s25-datepicker/s25.datepicker.module";

//@author: travis
@NgModule({
    declarations: [
        S25EvRequirementsComponent,
        S25EditableContactRolesComponent,
        S25EditableAdditionalOrgsComponent,
        S25OccurrencesConfigComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        S25DropdownPaginatedModule,
        S25ItemModule,
        S25EditableModule,
        S25LoadingInlineModule,
        S25NotificationPolicyModule,
        S25OrgDetailsModule,
        S25MultiselectModule,
        S25ToggleButtonModule,
        S25DatepickerModule,
    ],
    providers: [
        S25EvRequirementsComponent,
        S25EditableContactRolesComponent,
        S25EditableAdditionalOrgsComponent,
        S25OccurrencesConfigComponent,
    ],
    exports: [
        S25EvRequirementsComponent,
        S25EditableContactRolesComponent,
        S25EditableAdditionalOrgsComponent,
        S25OccurrencesConfigComponent,
    ],
})
export class S25EvDetailsModule {
    constructor() {}
}
